// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll
jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t=t/d-1)*t*t*t - 1) + b;
};

jQuery(document).ready(function(){
  jQuery('a[href*="#"]').click(function() {    
    var targetId = jQuery(this.hash);
    
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = jQuery(this.hash);
      target = target.length && target || jQuery('[name=' + this.hash.slice(1) +']');

      // ターゲット先へスクロール移動
      if (target.length) {
        var targetOffset = target.offset().top;
        jQuery('html,body').animate({ scrollTop: targetOffset }, 1200, 'swing');

        // ターゲット先がスライドパネルの場合はオープン
        if (target.next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
        } else {
          target.next(".js-slideBox").find(".js-slideBtn").addClass('is-active');
          target.next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
        }

        return false;
      }
    }
  });


});


// slide btn
$(function(){
  $(".js-slideBtn").each(function(index,element) {
    $(element).click(function() {
      $(this).toggleClass('is-active');
      $(element).next(".js-slidePanel").slideToggle(300);
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


// current
$(function() {
  var gNavItem = $("#dnav .js-gnavLink");

  var local_path_arr = $(location).attr('pathname').split('/');
  var local_dir = local_path_arr[1];

  gNavItem.each(function(){
    var href_path = $(this).attr("href");
    var href_path_arr = href_path.split('/');
    var href_dir = href_path_arr[1];
    
    if(href_dir == local_dir){
      $(this).addClass('is-current');
    } else {
      $(this).removeClass('is-current');
    }
  });

});


// バーガーメニュー開閉
$(function(){
  $("#js-drawerOpen").click(function() {
    if ($("#js-drawerOpen").hasClass('is-open')) {
      $("#js-drawerOpen").removeClass('is-open');
      $("#drawer").removeClass('is-open');
      $("#gnav").removeClass('is-open');
      $("#dnav").removeClass('is-open');
      $("#js-drawerOpen").addClass('is-close');
      $("#drawer").addClass('is-close');
      $("#gnav").addClass('is-close');
      $("#dnav").addClass('is-close');
    } else {
      $("#js-drawerOpen").removeClass('is-close');
      $("#drawer").removeClass('is-close');
      $("#gnav").removeClass('is-close');
      $("#dnav").removeClass('is-close');
      $("#js-drawerOpen").addClass('is-open');
      $("#drawer").addClass('is-open');
      $("#gnav").addClass('is-open');
      $("#dnav").addClass('is-open');
    }
  });
});


//fadein
$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('js-scrollIn');
    }
  });
});


// header & mv & parts scroll settings
function handle(event) {
  event.preventDefault();
}


$(window).on('load scroll resize', function(){  
  let winW    = $(window).width();
  let winscrW = window.innerWidth;        //スクロールバーを含む幅 
  let winH    = $(window).height();  //ウィンドウの高さ
  let scroll  = $(window).scrollTop();
  let fadeinitem  = $('.js-fadeinitem');
  let fadeinupitem  = $('.js-fadeinupitem');
  let slideupitem  = $('.js-slideupitem');
  let slideinbtn  = $('.js-slideinbtn');
  let revealtext  = $('.js-revealtext');
  let blurwhitem  = $('.js-blurwhitem');
  let charupitem  = $('.js-charupitem');

  //要素フェードイン
  fadeinitem.each(function(){
    let fiiTop = $(this).offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let fadeinitemPos = fiiTop - (winH * 0.8);

    if (scroll > fadeinitemPos) {
      $(this).addClass("js-fadein");
    }
  });

  //要素フェードイン&アップ
  fadeinupitem.each(function(){
    let fiuiTop = $(this).offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let fadeinupitemPos = fiuiTop - (winH * 0.8);

    if (scroll > fadeinupitemPos) {
      $(this).addClass("js-fadeinup");
    }
  });

  //要素スライドアップ
  slideupitem.each(function(){
    let suiTop = $(this).offset().top;

    // 要素が画面の上から50%(下から50%)に来た時に動く
    let slideupitemPos = suiTop - (winH * 0.5);

    if (scroll > slideupitemPos) {
      $(this).addClass("js-slideup");
    }
  });


  //要素ブラー表示
  blurwhitem.each(function(){
    let bwiTop = $(this).offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let blurwhitemPos = bwiTop - (winH * 0.8);

    if (scroll > blurwhitemPos) {
      $(this).addClass("js-blurWh");
    }
  });

  //要素CharUp表示
  charupitem.each(function(){
    let cuTop = $(this).offset().top;

    // 要素が画面の上から100%(下から0%)に来た時に動く
    let charupitemPos = cuTop - (winH * 1.0);

    if (scroll > charupitemPos) {
      $(this).addClass("js-charup");
    }
  });

  //要素スライドイン
  slideinbtn.each(function(){
    let sibTop = $(this).offset().top;

    // 要素が画面の上から90%(下から10%)に来た時に動く
    let slideinbtnPos = sibTop - (winH * 0.9);

    if (scroll > slideinbtnPos) {
      $(this).addClass("js-ismove");
    }
  });

  //要素reveal表示
  revealtext.each(function(){
    let rtTop = $(this).offset().top;

    // 要素が画面の上から75%(下から25%)に来た時に動く
    let revealtextPos = rtTop - (winH * 0.75);

    if (scroll > revealtextPos) {
      $(this).addClass("js-isdisplay");
    }
  });

});


///// スクロールに合わせて背景色を変化させる /////
$(window).scroll(function () {
  if ( $("#wrap").hasClass("-front") ) {
    let winH = $(window).height();  //ウィンドウの高さ
    let scrollAjust = winH / 4 * 3;  //変化開始する地点を、この数値px分だけ上にずらす
    let about = $("#about");
    let fab3 = $("#front_about_box3");
    let fab3Top = fab3.offset().top - scrollAjust;
    let fab3TextTop = $("#front_about_box3_text").offset().top - scrollAjust;
    let scroll = $(window).scrollTop();
    let scrollFab3Diff = scroll - fab3Top;  //id="front_about_box3"上端からのスクロール距離
    let fab3Fab3TextDiff = fab3TextTop - fab3Top;  //id="front_about_box3"上端からid="front_about_box3_text"上端までの高さ
    let scrollPer = scrollFab3Diff / fab3Fab3TextDiff;  //front_about_box3の高さの何パーセントスクロールしたかを計算して、それをabout section全体の背景のopacityに設定する
      
    about.css('background-color', 'rgba(43, 43, 43, ' + scrollPer + ')');
  }
});


///// スクロールに合わせて波打つテキストを動かす /////
$(window).scroll(function (){
  let textPath = document.querySelector('#text-path');
  
  if ( textPath != null ) {
    let textContainer = document.querySelector('#text-container');
    let path = document.querySelector( textPath.getAttribute("href") );
    let pathLength = path.getTotalLength();
  
    updateTextPathOffset(textPath, pathLength);
  
    window.addEventListener('scroll', onScroll(textPath, textContainer, pathLength));
  }
});

function updateTextPathOffset(textPath, offset){
  textPath.setAttribute('startOffset', offset); 
}

function onScroll(textPath, textContainer, pathLength) {
  requestAnimationFrame(function() {
    let rect = textContainer.getBoundingClientRect();
    let scrollPercent = rect.y / window.innerHeight;
    let offsetPos = ( scrollPercent * pathLength / 2 ) - 1620;  // 1620 = 3240 / 2(3240はSVGのパスの横幅)
//    var offsetPos = scrollPercent * 2 * pathLength;
//    var scrollPercent = rect.y / window.innerHeight;
    updateTextPathOffset( textPath, offsetPos );
  });
}


// 電話番号リンク
if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
  $(function() {
    $('.js-tellink').each(function() {
      var str = $(this).html();

      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      }
    });
  });
}


///// 指定行数で以下略にする /////
// 省略対象のclassを指定
var omit = document.getElementsByClassName('js-omit');
$(window).on('load resize', function () {
  //表示する行数を取得(HTMLの「data-omitline-max」属性に行数を数値で指定する)
  lineNum = $('.js-omit').data('omitline'); // 元の文章をdata-origintextに退避

  omit_init(); // 文章を省略する処理。

  omit_sentence();
});


//文字省略の初期設定
function omit_init() {
  for (var i = 0; i < omit.length; i++) {
    if (omit[i].dataset.origintext === undefined) {
      var sentence = omit[i].textContent;
      omit[i].dataset.origintext = sentence;
    }
  }
}


//文字省略
//line - 表示する行数(ここで数値した行数以上は、省略する)
function omit_sentence() {
  var line = new Array();
  var winscrW = window.innerWidth;
  /* スクロールバーを含む幅 */

  var dispMode = "pc";

  if (winscrW <= 800) {
    dispMode = "sp";
  } // 対象の文章を1文字ずつspanで区切る


  for (var i = 0; i < omit.length; i++) {
    line[i] = omit[i].getAttribute('data-omitline-max'); //表示する行数

    if (omit[i].hasAttribute('data-omit-target')) {
      //data-omit-target属性がある場合のみ、対象ごとに独自処理
      omitTarget = omit[i].getAttribute('data-omit-target');
      /*
       * トップページのお客様の声の文章用
       * 375px以下→1列なので変更なし、420px以下→2列で小さいので2行減らす、480px以下→2列で多少小さいので1行減らす
       */

      if (omitTarget == 'toppage-rev-txt') {
        if (winscrW <= 700) {
          line[i] = line[i] - 3; //3行減らす
        } else if (winscrW <= 1000) {
          line[i] = line[i] - 2; //2行減らす
        }
      }
    }

    omit[i].innerHTML = divide_sentence(omit[i].dataset.origintext);
  } // 区切った文字の行数(座標)を調べる


  for (var i = 0; i < omit.length; i++) {
    var s = omit[i].getElementsByTagName('span'); // 省略する位置を調べる

    var omitIndex = check_character_row_count(s, line[i]); // 省略した文章を反映

    var sentence = omit[i].dataset.origintext;

    if (omitIndex !== undefined) {
      sentence = sentence.slice(0, omitIndex) + '…';
    }

    omit[i].textContent = sentence;
  }
}

  
//文章を1文字ずつspanで括って返す
//text - 分割する文字列
function divide_sentence(text) {
  var textArr = text.split('');
  var divideText = '';

  for (var i = 0; i < textArr.length; i++) {
    divideText += '<span>' + textArr[i] + '</span>';
  }

  divideText += '<span>' + '…' + '</span>';
  return divideText;
}


//省略する位置を調べて返す
//@param {object} text - spanで区切った文字群
//@param {number} line - 省略する行数
function check_character_row_count(text, line) {
  var lineCount = 0;
  var beforeTop = text[0].getBoundingClientRect().top - 1;
  var omitIndex = undefined;

  for (var i = 0; i < text.length - 1; i++) {
    // 文章の最後に確認用の省略文字が入っているので、最後の文字はチェックから除外
    var currentTop = text[i].getBoundingClientRect().top; // 今回の文字の座標が前回の文字の座標より先に進んでいる時

    if (currentTop > beforeTop) {
      beforeTop = currentTop;
      lineCount++;
    } // 調べている文字が省略行数を過ぎた時


    if (lineCount > line) {
      // 省略の記号を入れるため、文字の幅から切り取り位置の調整
      var ellipsisWidth = text[text.length - 1].getBoundingClientRect().width + 1;
      var c = 0;
      var w = 0;

      do {
        c++;
        w += text[i - c].getBoundingClientRect().width;
      } while (w < ellipsisWidth);

      omitIndex = i - c;
      break;
    }
  }

  return omitIndex;
}


///// READ MOREボタンクリック時、さらに投稿を読み込んで表示する /////
jQuery(function($){
  let get_post_num = 3  // もっと読み込むボタンで取得する件数

  //archive側で設定したdata属性の値を取得
  let load = $(".js-loadresult");
  let post_type = load.data("post-type");
  let all_count = load.data("count"); //カスタム投稿の全件数
  let now_post_num = load.data("init-num"); // 初期表示件数
  let srch_condition = load.data("condition"); // 絞り込み条件
  let displaypostid = load.data("displaypostid"); // 除外ID(現在表示中ページの投稿ID)

  //admin_ajaxにadmin-ajax.phpの絶対パス指定（相対パスは失敗する）
  let host_url = location.protocol + "//" + location.host;
  let admin_ajax = host_url + '/wp/wp-admin/admin-ajax.php';

  $(document).on("click", ".js-readmore_btn", function () {
    //読み込み中はボタン非表示
    $('.js-readmore_btn').hide();

    //ajax処理。data{}のactionに指定した関数を実行、完了後はdoneに入る
    $.ajax({
      type: 'POST',
      url: admin_ajax,
      data: {
        'action' : 'my_ajax_action', //functions.phpで設定する関数名
        'now_post_num': now_post_num,
        'get_post_num': get_post_num,
        'post_type': post_type,
        'condition': srch_condition,
        'displaypostid': displaypostid,
      },
    })
    .done(function(data){ //my_ajax_action関数で取得したデータがdataに入る
      //.js-loadresultにデータを追加
      load.append(data); 
      //表示件数を増やす
      now_post_num = now_post_num + get_post_num;
      
      //まだ全件表示されていない場合、ボタンを再度表示
      if (all_count > now_post_num) { 
        $('.js-readmore_btn').show();
        
      //全件表示された場合は、.js-loadresultの付いたタグに.js-displayallを付ける
      } else {
        $('.js-loadresult').addClass("js-displayall");
      }
      
      let winscrW = window.innerWidth;  //スクロールバーを含む幅 
      
      //各テキストコンテンツの高さを揃える
      if ( winscrW < 480 ) {
        $(".c-card_ttl").tile(1);
        
        if ( post_type == "works" ) {
          $(".c-card_client").tile(1);
          $(".c-card_client_sub").tile(1);
        } else if ( post_type == "blog" ) {
          $(".c-card_ttl").tile(1);
          $(".c-card_author").tile(1);
        }
      } else if ( winscrW < 800 ) {
        $(".c-card_ttl").tile(2);

        if ( post_type == "works" ) {
          $(".c-card_client").tile(2);
          $(".c-card_client_sub").tile(2);
        } else if ( post_type == "blog" ) {
          $(".c-card_ttl").tile(2);
          $(".c-card_author").tile(2);
        }
      } else {
        $(".c-card_ttl").tile(3);

        if ( post_type == "works" ) {
          $(".c-card_client").tile(3);
          $(".c-card_client_sub").tile(3);
        } else if ( post_type == "blog" ) {
          $(".c-card_ttl").tile(3);
          $(".c-card_author").tile(3);
        }
      }
    })
    .fail(function(){
      alert('作品事例の読み込みに失敗しました。ページを再読み込みしてください。');
    })
  });
});